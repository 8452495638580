.catagory-viewer {
  margin-bottom: 0.5rem;
}

.catagory-container__edit {
  margin-bottom: 0.35rem;
  display: flex;
  align-items: center;

  background-color: white;

  gap: 0.5rem;

  height: min-content;
  border-radius: 0.5rem;
  transition: all 250ms;
}

.catagory-container__edit:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
}

.catagory-item-left-margin {
  margin-left: 1rem;
}
.catagory-container__edit__item-info-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0.75rem;
  padding-inline-start: 0rem;
  cursor: pointer;
}
.cat-drag-icon-container {
  padding-inline: 1rem 0.5rem;
  align-self: stretch;
  display: flex;
  align-items: center;
  touch-action: none;
}
.catagory-container__edit-image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 100%; */
  width: max-content;
  gap: 1.5rem;
}

.cat-drag-icon {
  height: 8px;
}

.catagory-container__edit-image-img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 0.25rem;
  background-color: #6d6d6d2f;
}
.catagory-container__edit-info-container {
  display: flex;
  flex-grow: 1;
  width: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.catagory-container__name {
  text-wrap: nowrap;
  font-size: 1.2rem;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.catagory-container__edit-function {
  position: relative;
  display: flex;

  align-items: center;
  justify-content: flex-end;
  /* flex-wrap: wrap; */
  width: max-content;
  gap: 1.5rem;
  margin-right: 0.5rem;
}

.item-price-container {
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  overflow: hidden;
  background: rgb(224, 224, 224);
  color: #3b3b3b;
  max-width: 200px;
  width: max-content;
  font-size: 1.1rem;
}
.item-price-container-currency {
  background-color: #c7c7c7;

  padding: 0.5rem;
}
.item-price-container-value {
  border: none;
  padding: 0px 5px;
  padding-inline: 0.5rem;

  width: 100%;
}

.catagory-container__edit-view-item-butttons {
  position: relative;
  cursor: pointer;
  border: none;
  background: none;
  height: 100%;
  /* background-color: red; */
  /* padding: 0.5rem; */
}

.view-item-butttons-img {
  height: 15px;
  transition: 0.25s;
}
.view-item-butttons-img_big {
  height: 21px;
}

/* ADD ITEM */
.add-item-container {
  background: white;
  height: 3.5rem;
  width: 100%;
  border: 0px solid rgb(209, 209, 209);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.35rem;
  transition: all 250ms;
}
.add-item-container:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
}

.add-item-container-name {
  margin-left: 30px;
  display: flex;
  align-items: center;
}
.add-item-container-name > img {
  width: 1rem;
  margin-right: 1rem;
}

/* MENU CHECKBOX */
.menu-check-box-container {
}

.menu-check-box-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.menu-check-box {
  opacity: 0;
  width: 0;
  height: 0;
}

.menu-check-box-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.menu-check-box-slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.menu-check-box:checked + .menu-check-box-slider {
  background-color: #13e21e;
}

.menu-check-box:focus + .menu-check-box-slider {
  box-shadow: 0 0 1px #13e21e;
}

.menu-check-box:checked + .menu-check-box-slider::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.menu-button-img {
  height: 21px;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .cat-drag-icon-container {
    display: none;
  }
  .item-price-container {
    font-size: 0.9rem;
  }
  .catagory-container__name {
    font-size: 0.9rem;
  }
  .catagory-container__edit__item-info-wrapper {
    padding-inline-start: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .item-price-container {
    display: none;
  }

  .catagory-container__edit-image {
    gap: 0.5rem;
  }
  .catagory-container__edit__item-info-wrapper {
    gap: 0.5rem;
  }
  .catagory-container__edit-function {
    gap: 0.5rem;
  }
}
