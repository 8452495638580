.catagory-container {
  flex-shrink: 0;
  flex-grow: 0;

  width: 6rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
}

.catagory-img {
  width: 7rem;
  height: 4.5rem;
  object-fit: contain;
}
.catagory-name {
  margin: 0;
  margin-block: 0;
  margin-inline: 0;
  text-align: center;
  font-weight: 400;
  height: min-content;
  width: inherit;
  font-size: 1rem;

  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-items {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem;
  border-radius: 0.5rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
  gap: 20px;
}

.menu-items-info {
  min-width: 40px;
  white-space: nowrap;
}

.menu-items-name {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: inherit;
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
}
.menu-items-note {
  font-size: 1rem;
  margin-bottom: 0.4rem;
}
.menu-items-price {
  font-size: 0.9rem;
}

.menu-items-img-container {
  height: 6rem;
  width: 6rem;
}

.menu-items-img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 0.5rem;
}

.popup-conatainer {
  backdrop-filter: blur(1.5px);
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: grid;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.popUp-box {
  width: calc(100dvw - 5rem);
  max-width: 20rem;
  padding: 0.5rem 1rem;
  height: max-content;
  border-radius: 0.5rem;
}
.popUp-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}
.popUp-close-button {
  border: none;
  background: none;
  cursor: pointer;
}
.popUp-main {
  display: flex;
  flex-direction: column;
}

.popUp-button {
  background: none;
  border: none;
  text-align: left;
  padding: 1.1rem 0rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  cursor: pointer;
  font-size: 1rem;
}

.popUp-button:last-child {
  border-bottom: none;
}

.menu-drop-down-container {
  border-style: solid;
  border-width: 2px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.drop-down-header {
  padding: 1rem 0.6rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.drow-down-img {
  width: 1rem;
}

/* FOOTER */
.footer-container {
  /* background-color: red; */
  /* margin: -1rem; */
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.9rem;
}

.social-links-container {
  padding: 0.5rem;
}

.social-links {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.footer-copy-right {
}

.item-viewer-container {
  /* width: 18rem; */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: calc(100% - 4rem);
  max-width: 400px;

  height: max-content;
  max-height: calc(100dvh - 20%);
  border-radius: 0.5rem;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.055); */
  animation-name: item-pop-out;
  animation-duration: 0.5s;

  overflow-y: scroll;

  scrollbar-width: none;
}

.item-viewer-container::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.popup-conatainer:has(.item-viewer-container) {
  animation-name: slow-shadow;
  animation-duration: 0.5s;
}

@keyframes item-pop-out {
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slow-shadow {
  from {
    background: transparent;
  }
  to {
    transform: rgba(0, 0, 0, 0.5);
  }
}

.item-viewer-header {
  width: 100%;
}

.item-viewer-img {
  width: inherit;
  object-fit: cover;
}

.item-viewer-main {
  padding: 1rem 1rem 2rem 1rem;
}

.item-viewer-name {
  font-size: 1.5rem;
}

.item-viewer-note {
  font-size: 1rem;
}

.item-viewer-price-wrapper {
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */

  margin-top: 1rem;
}
.item-viewer-price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-viewer-price {
  margin: 0.25rem 0;
}

/* SLIDE SHOW */

.menu-carousel-container {
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 1rem 0;
}
.menu-carousel-image-container {
}
.menu-carousel-image {
  object-fit: cover;
  aspect-ratio: 16/8;
}

/* LOADER */

button:focus {
  outline: 0;
}
button:active {
  outline: 0;
}

.layout-container {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 1fr;
  /* height: 100vh;
  width: 100%;
   */
  overflow: hidden;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.layout-section {
  --border_line_color: rgba(0, 0, 0, 0.274);
  border-left: solid 1px var(--border_line_color);
  border-right: solid 1px var(--border_line_color);
  display: flex;
  height: 100vh;
  position: relative;
}

.layout-img {
  margin: auto;
  max-height: 600px;
  max-width: 600px;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 70%;
  margin: auto;
  gap: 2rem;
}
.home-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
}
.home-welcome-message {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.home-logo {
  max-width: 30rem;
  height: 15rem;
  object-fit: contain;
}
.home-language-option {
  font-size: 1.1rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-weight: 400;
}

.home-to-menu-button:visited,
.home-to-menu-button:link {
  color: inherit;
  text-decoration: inherit;
}

.home-info-container {
  width: 100%;
  text-align: center;
}
.home-options {
  padding: 1rem 0rem;
  border-bottom: solid 1.5px rgba(129, 129, 129, 0.199);
}

.home-options:nth-of-type(1) {
  border-top: solid 1.5px rgba(129, 129, 129, 0.199);
}

.home-social-links {
  /* position: absolute; */
  /* bottom: 0; */
  display: flex;
}

/* MENU  */

.menu-item-container {
  min-height: 61dvh;
  padding-bottom: 1rem;
}

.menu-container {
  padding: 0.7rem;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: hidden;
}

.menu-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.menu-header-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  height: 4rem;
  align-items: center;
}

.menu-logo-container {
  display: flex;
  justify-content: center;
}
.menu-logo {
  height: 4rem;
  object-fit: contain;
}

.menu-button {
  font-weight: 600;
  font-size: 1.5rem;
  /* padding: 0.5rem 1rem; */
  background-color: rgba(136, 136, 136, 0.445);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 0px rgba(2, 2, 2, 0.527);
  border: none;
  width: min-content;
  width: 50px;
  height: 50px;
}
.menu-button:link,
.menu-button:visited {
  color: inherit;
  text-decoration: inherit;
}
.menu-button-img {
  width: 16px;
  height: 16px;
}

.set-item-end {
  display: flex;
  flex-direction: row-reverse;
  padding: 0.5rem;
  align-items: center;
  gap: 0.4rem;
}

.menu-language-select-arrow {
  margin-top: 0.1rem;
}
.menu-catagories-container {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* width: 100%; */
}

.menu-carousel-container {
  /* display: flex; */
  position: relative;
}

.menu-carousel-button {
  background-color: rgba(172, 172, 172, 0.658);
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 35%;
  left: calc(0% + 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  outline: none;
}

.menu-carousel-button:hover {
  background-color: rgba(172, 172, 172, 0.89);
}

.carousel-button-right {
  left: calc(100% - 40px);
}

.menu-carousel-button > * {
  width: 1rem;
  height: 1rem;
}

.catagories-carousel-blur-left {
  background: rgba(124, 124, 124, 0.1);
  backdrop-filter: blur(1px);
  /* mask: linear-gradient(transparent, black 60%); */

  position: absolute;
  box-shadow: 0px 0px 50px 3px rgba(124, 124, 124, 0.5);
  width: 10px;
  height: 100%;
}
.catagories-carousel-blur-left::after {
  width: 10px;
  height: 10px;
  background: red;
}

.menu-catagories {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 4px 5px;
  scrollbar-width: none;
  gap: 1rem;

  scroll-snap-type: x mandatory;
}

.catagories-container-button {
  background: none;
  border: none;
  cursor: pointer;

  scroll-snap-align: start;
  scroll-margin: 4rem;
}

.catagory-item-line {
  position: relative;
  margin: 0;
  padding: 0;
  opacity: 1;
  border-top: 1px solid inherit;
  border-width: 1px;
}

.line-to-right {
  animation-name: line-transform-right;
  animation-duration: 0.5s;
}
@keyframes line-transform-right {
  from {
    left: -9rem;
  }
  to {
    left: 0rem;
  }
}

.line-to-left {
  animation-name: line-transofmr-left;
  animation-duration: 0.5s;
}

@keyframes line-transofmr-left {
  from {
    left: 9rem;
  }
  to {
    left: 0rem;
  }
}

.menu-catagories::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.menu-catagory-info {
  text-align: center;
  margin-bottom: 1rem;
}
.menu-catagory-name {
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
}
.menu-catagory-notes {
}

@media screen and (max-width: 1024px) {
  .layout-container {
    grid-template-columns: 1fr 0fr;
  }
  .layout-section {
    display: none;
  }
}
@media screen and (max-width: 820px) {
  .menu-carousel-button {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .home-container {
    width: calc(100% - 2rem);
  }
  .home-to-menu-button {
    width: 90%;
  }
}
