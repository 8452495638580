html,
body,
#app,
#app > div {
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-gradient-background-animated {
  background: linear-gradient(
    203deg,
    rgba(147, 126, 180, 0.1) 0%,
    rgba(98, 117, 157, 0.5) 50%,
    rgba(140, 174, 215, 0.1) 100%
  );

  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.custom-canvas-dispaly-size > * {
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: 100%;
}

.custom-stripes-background {
  background-image: linear-gradient(
    -45deg,
    #7e7e7e 25%,
    transparent 25%,
    transparent 50%,
    #7e7e7e 50%,
    #7e7e7e 75%,
    transparent 75%
  );
  background-size: 15px 15px;
}

.custom-edit-slide {
  width: 100%;
  animation: slideShow 0.3s linear;
}

@media (min-width: 768px) {
  .custom-edit-slide {
    width: 40%;
  }
}

@keyframes slideShow {
  from {
    transform: translate(1000px);
  }
  to {
    transform: translate(0px);
  }
}

.custom-showing-animation {
  animation-name: langShow;

  animation-duration: 0.4s;
}

@keyframes langShow {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
